import "./src/styles/global.css";

import AOS from "aos";
import "aos/dist/aos.css";

import * as React from "react";
import type { GatsbyBrowser } from "gatsby";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  React.useEffect(() => {
    AOS?.init();
  }, []);
  return <>{element}</>;
};
